<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <input type="text" v-model="searchParams.search" v-on:keyup.enter="handleSearch" class="form-control form-control-solid w-250px me-2" :placeholder="orderProductStatusConstant.SEARCH_TITLE" />
        </div>
        <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
          <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
        </el-tooltip>
        <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
        </el-tooltip>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable :table-data="tableData" :table-header="tableHeader" :rows-per-page="searchParams.recordsPerPage" :empty-table-text="noDataFound" :loading="loading" :current-page="1" :enable-items-per-page-dropdown="true" :total="total" ref="updateProps" @current-change="handleCurrentChange" @items-per-page-change="handleSizeChange">
        <template v-slot:cell-date="{ row: data }">
          {{formatDateTime(data.logdate)}}
        </template>
        <template v-slot:cell-user="{ row: data }">
          {{data.causer ? data.causer.name: '-'}}
        </template>

        <template v-slot:cell-event="{ row: data }">
          <span v-if="data.event=='created'" class="badge badge-success">Created</span>
          <span v-if="data.event=='updated'" class="badge badge-warning">Updated</span>
          <span v-if="data.event=='deleted'" class="badge badge-danger">Deleted</span>
          <span v-if="data.event=='restored'" class="badge badge-info">Restored</span>
        </template>
        <template v-slot:cell-log_name="{ row: data }">
          {{data.log_name}}
        </template>
        <template v-slot:cell-description="{ row: data }">
          {{data.description}}
        </template>
        <template v-slot:cell-properties="{ row: data }">
          {{data.properties}}
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <el-tooltip class="box-item" effect="dark" :content="generalConstants.VIEW" placement="top">
            <button type="button" data-bs-toggle="modal" data-bs-target="#changelog_modal" @click.prevent="setChangeLogs(data)" class="btn btn-icon btn-light btn-sm me-2">
              <i class="bi bi-eye"></i>
            </button>
          </el-tooltip>
        </template>
      </Datatable>
    </div>

    <div class="modal fade" id="changelog_modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-body-main">
            <div class="modal-header">
              <h5 class="modal-title">Change Log Report : {{sectionName}}
                <span v-if="eventName=='created'" class="badge badge-success">Created</span>
                <span v-if="eventName=='updated'" class="badge badge-warning">Updated</span>
                <span v-if="eventName=='deleted'" class="badge badge-danger">Deleted</span>
                <span v-if="eventName=='restored'" class="badge badge-info">Restored</span>
              </h5>
              <button type="button" class="btn-close" id="orderclosebutton" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="table-responsive" v-if="!Array.isArray(popupData)">
                <table id="product-list-table" class="table border table-row-bordered table-row-gray-300 gy-6 gs-6 row-border w-100" >
                  <thead>
                    <tr class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                      <th class="min-w-200px align-middle text-body">Column</th>
                      <th v-if="popupData.old" class="align-middle text-body">Old Value</th>
                      <th v-if="popupData.attributes" class="align-middle text-body">New Value</th>
                    </tr>
                  </thead>
                  <tbody class="fw-bold text-gray-600">
                    <template v-if="popupData">
                      <tr v-for="(data, key) in (popupData.attributes ? popupData.attributes : popupData.old)" :key="key">
                        <td class="align-middle bg-light-warning" width="30%">
                          <a class="d-block text-body">{{humanize(key)}}</a>
                        </td>
                        <td v-if="popupData.old" class="align-middle bg-light-danger" width="35%">
                          <span class="text-body" v-html="popupData.old[key] ? popupData.old[key] : 'N/A'"></span>
                        </td>
                        <td v-if="popupData.attributes" class="align-middle bg-light-success" width="35%">
                          <span class="text-body" v-html="data ? data : 'N/A'"></span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <h4>{{activityDescription}}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { notificationFire } from "@/composable/notification.js";
import { formatText } from "@/core/helpers/common";
import { useRoute, useRouter } from "vue-router";
import globalConstant from "@/core/data/globalConstant.js";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { useStore } from "vuex";
import { formatDateTime } from "@/core/helpers/common";

export default {
  name: "product-options",
  components: {
    Datatable,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const updateProps = ref(null);
    const search = ref("");
    const sectionName = ref("");
    const eventName = ref("");
    const activityDescription = ref("");
    const pageSize = ref(10);
    const total = ref(0);
    const tableData = ref([]);
    const currentpage = ref(1);
    const loading = ref(false);
    const generalConstants = globalConstant.general;
    const noDataFound = ref(generalConstants.LOADING);
    const orderProductStatusConstant = globalConstant.orderProductStatus;
    const moduleName = ref(route.meta.module ? route.meta.module : "");
    const popupData = ref([]);
    const searchParams = reactive({
      search: "",
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value),
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
      isDeleteAllowed: false,
    });
    
    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: true,
      },
      {
        name: "Date",
        key: "date",
        sortable: true,
      },
      {
        name: "User",
        key: "user",
        width: "w-200px",
      },
      {
        name: "Event",
        key: "event",
        sortable: false,
      },
      {
        name: "Section",
        key: "log_name",
        sortable: false,
      },
      {
        name: "Message",
        key: "description",
        sortable: false,
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        sortable: false,
      },
    ]);

    //Get all Order Product Status
    const getAuditLogs = async () => {
      loading.value = true;
      await ApiService.query("audit-logs", { params: searchParams })
        .then(({ data }) => {
          if (data.data.data.length) {
            tableData.value = [];
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.data
            );
            total.value = data.data.total;
            updateProps.value.updateProps(
              total.value,
              searchParams.recordsPerPage,
              searchParams.pageNub
            );
          } else {
            updateProps.value.updateProps(
              total.value,
              searchParams.recordsPerPage,
              searchParams.pageNub,
              generalConstants.NO_DATA_FOUND
            );
          }
          setCurrentPageBreadcrumbs(
            formatText(route.name) + " (" + total.value + ")",
            [{ name: formatText(route.name), active: true }]
          );
          loading.value = false;
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
          tableData.value = [];
          loading.value = false;
        });
    };
    //Set the popup data
    const setChangeLogs = async (data) => {
      loading.value = true;
      popupData.value = data.properties;
      eventName.value = data.event;
      sectionName.value = data.log_name;
      activityDescription.value = data.description;
      loading.value = false;
    };

    //For Pagination Size Change
    const handleSizeChange = (number) => {
      if (total.value > 10) {
        searchParams.recordsPerPage = number
        handleCurrentChange(1);
      }
    };

    //For Pagination Number Change
    const handleCurrentChange = (number) => {
      searchParams.pageNub = number;
      getAuditLogs();
    };

    //For Search Box handle event
    const handleSearch = () => {
      handleCurrentChange(1);
    };

    //Reset search value
    const resetSearch = () => {
      if (searchParams.search.length > 0) {
        searchParams.search = "";
        getAuditLogs();
      }
    };

    const humanize = (str) => {
      var i, columnName = str.split('_');
      for (i=0; i<columnName.length; i++) {
        columnName[i] = columnName[i].charAt(0).toUpperCase() + columnName[i].slice(1);
      }
      return columnName.join(' ');
    }

    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [
        { name: formatText(route.name), active: true },
      ]);
      await getAuditLogs();
    });

    return {
      tableData,
      search,
      loading,
      getAuditLogs,
      total,
      currentpage,
      pageSize,
      handleSizeChange,
      handleCurrentChange,
      handleSearch,
      resetSearch,
      searchParams,
      tableHeader,
      generalConstants,
      updateProps,
      noDataFound,
      permission,
      orderProductStatusConstant,
      formatDateTime,
      setChangeLogs,
      popupData,
      loading,
      sectionName,
      eventName,
      activityDescription,
      humanize
    };
  },
};
</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
</style>
